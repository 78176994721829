define("discourse/plugins/discourse-category-creator/discourse/components/user-color-input", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "@ember-decorators/object", "discourse/components/text-field", "@ember/modifier", "@ember/template-factory"], function (_exports, _component, _object, _component2, _object2, _textField, _modifier, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Simply copied from https://github.com/discourse/discourse/blob/3cde55b76f6d681a243876b360bd8368c0a81ac6/app/assets/javascripts/admin/addon/components/color-input.js
  // But that component is admin only
  const UserColorInput = dt7948.c(class UserColorInput extends _component.default {
    onlyHex = true;
    styleSelection = true;
    get maxlength() {
      return this.onlyHex ? 6 : null;
    }
    static #_ = (() => dt7948.n(this.prototype, "maxlength", [(0, _object.computed)("onlyHex")]))();
    get normalizedHexValue() {
      return this.normalize(this.hexValue);
    }
    static #_2 = (() => dt7948.n(this.prototype, "normalizedHexValue", [(0, _object.computed)("hexValue")]))();
    normalize(color1) {
      if (this._valid(color1)) {
        if (!color1.startsWith("#")) {
          color1 = "#" + color1;
        }
        if (color1.length === 4) {
          color1 = "#" + color1.slice(1).split("").map(hex1 => hex1 + hex1).join("");
        }
      }
      return color1;
    }
    onHexInput(event1) {
      if (this.onChangeColor) {
        this.onChangeColor(this.normalize(event1.target.value || ""));
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "onHexInput", [_object.action]))();
    onPickerInput(event1) {
      this.set("hexValue", event1.target.value.replace("#", ""));
    }
    static #_4 = (() => dt7948.n(this.prototype, "onPickerInput", [_object.action]))();
    hexValueChanged() {
      const hex1 = this.hexValue;
      if (this.onChangeColor) {
        this.onChangeColor(this.normalize(hex1));
      }
      if (this._valid()) {
        this.element.querySelector(".picker").value = this.normalize(hex1);
      }
    }
    static #_5 = (() => dt7948.n(this.prototype, "hexValueChanged", [(0, _object2.observes)("hexValue", "brightnessValue", "valid")]))();
    _valid() {
      let color1 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.hexValue;
      return /^#?([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/.test(color1);
    }
    static #_6 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.onlyHex}}<span class="add-on">#</span>{{/if}}<TextField
          @value={{this.hexValue}}
          @maxlength={{this.maxlength}}
          @input={{this.onHexInput}}
          class="hex-input"
          aria-labelledby={{this.ariaLabelledby}}
        />
        <input
          class="picker"
          type="color"
          value={{this.normalizedHexValue}}
          {{on "input" this.onPickerInput}}
          aria-labelledby={{this.ariaLabelledby}}
        />
      
    */
    {
      "id": "MQNqKXRQ",
      "block": "[[[1,\"\\n    \"],[41,[30,0,[\"onlyHex\"]],[[[10,1],[14,0,\"add-on\"],[12],[1,\"#\"],[13]],[]],null],[8,[32,0],[[24,0,\"hex-input\"],[16,\"aria-labelledby\",[30,0,[\"ariaLabelledby\"]]]],[[\"@value\",\"@maxlength\",\"@input\"],[[30,0,[\"hexValue\"]],[30,0,[\"maxlength\"]],[30,0,[\"onHexInput\"]]]],null],[1,\"\\n    \"],[11,\"input\"],[24,0,\"picker\"],[16,2,[30,0,[\"normalizedHexValue\"]]],[16,\"aria-labelledby\",[30,0,[\"ariaLabelledby\"]]],[24,4,\"color\"],[4,[32,1],[\"input\",[30,0,[\"onPickerInput\"]]],null],[12],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-category-creator/discourse/components/user-color-input.js",
      "scope": () => [_textField.default, _modifier.on],
      "isStrictMode": true
    }), this))();
  }, [(0, _component2.classNames)("color-picker")]);
  var _default = _exports.default = UserColorInput;
});