define("discourse/plugins/discourse-category-creator/discourse/components/category-creator-button", ["exports", "@ember/service", "@ember/object", "@glimmer/component", "discourse/plugins/discourse-category-creator/discourse/components/modal/category-creator-dialog", "discourse/components/d-button", "@ember/component", "@ember/template-factory"], function (_exports, _service, _object, _component, _categoryCreatorDialog, _dButton, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CategoryCreatorButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "modal", [_service.inject]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    showCategoryCreatorDialog() {
      this.modal.show(_categoryCreatorDialog.default, {});
    }
    static #_3 = (() => dt7948.n(this.prototype, "showCategoryCreatorDialog", [_object.action]))();
    get showButton() {
      return this.currentUser && this.currentUser.category_creator_allowed;
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.showButton}}
          <DButton
            @action={{this.showCategoryCreatorDialog}}
            @label="category_creator.dialog_button"
          />
        {{/if}}
      
    */
    {
      "id": "eZiYR9Ev",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"showButton\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@action\",\"@label\"],[[30,0,[\"showCategoryCreatorDialog\"]],\"category_creator.dialog_button\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-category-creator/discourse/components/category-creator-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CategoryCreatorButton;
});